// import './App.css';
import React, { Suspense } from "react";

import Layout from "../layout";
import Slider from "./slider";
import Popup from "./popUp/PopUp";
import { useEffect, useState } from "react";

const Partner = React.lazy(() => import("./count/Partner"));

const Contact = React.lazy(() => import("./Contact"));

const FrequentlyAskedQuestions = React.lazy(() =>
  import("./FrequentlyAskedQuestions")
);

const HomeSchema = React.lazy(() => import("../helper/SEO/Home"));

// import MajorServices from "../../CutisHome/MajorServices";
const AboutCutis = React.lazy(() => import("../../CutisHome/AboutCutis"));

const Celebrities = React.lazy(() => import("../../CutisHome/Celebrities"));
const PatientCelebrity = React.lazy(() =>
  import("../../CutisHome/PatientCelebrity")
);

const OurBrands = React.lazy(() => import("../../CutisHome/OurBrands"));

const Trending = React.lazy(() => import("../../CutisHome/Trending"));

const Unique = React.lazy(() => import("../../CutisHome/Unique"));

const Testimonials = React.lazy(() => import("../../CutisHome/Testimonials"));

const BeforeAfter = React.lazy(() => import("../../CutisHome/BeforeAfter"));

const MajorServices = React.lazy(() => import("../../CutisHome/MajorServices"));

const Faq = React.lazy(() => import("./faq/Faq"));

const Home = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const set = setTimeout(() => setShow(true), 3000);

    return () => {
      clearTimeout(set);
    };
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Layout>
        {show && <Popup show={show} setShow={setShow} />}
        {/* <p className="note-mask-content">
          {" "}
          Note: Due to technical issues, we are unable to attend the phone
          calls, sorry for the inconvenience we are working on it, we will
          revert as soon as possible.
        </p> */}
        <Slider />
        <div className="nabh_container">
          <img src="/nabh2.png" alt="NABH" />
          <p>Now we are NABH Accredited</p>
        </div>
        <MajorServices />
        <AboutCutis />
        <HomeSchema />
        <PatientCelebrity />
        <OurBrands />
        <Trending />
        <Unique />
        <Testimonials />
        <BeforeAfter />
        <Faq />
        <Partner />
        <FrequentlyAskedQuestions />
        <Contact />
      </Layout>
    </Suspense>
  );
};

export default Home;

{
  /* <Celebrities /> */
}
