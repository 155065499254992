import React from "react";
import SectionTitle from "./home/SectionTitle";

// const iframeStyle = { border: 0, width: "100%", height: "350px" };
let submitText;
let testlink = window.location.href;
let testlink2 = testlink.split("/").slice(3).join("/").replace("#", "");

let testlink3 = "/" + testlink2;
// console.log(testlink3);

if (testlink3 === "/careers/thank-you") {
  submitText = "Your message has been sent. Thank you!";
}
const CarrerForm = () => {
  return (
    <>
      {/* ======= Contact Section ======= */}
      <section id="contact" className="contact">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6">
              <SectionTitle
                title={"CACS is hiring for the following positions"}
                desc={""}
              />
              <h5 className="medalPTag">
                <strong className="medalPTagStrong">ACADEMICS </strong>
              </h5>
              <ul className="presentationsUl">
                <li>
                  <p>
                    <strong className="medalPTagStrong">Consultants – </strong>
                    Post MD Dermatology candidates with a minimum of 2 years of
                    experience and no intentions of private practice.
                    <a target="_blank" href="/consultant">
                      <span className="car-btn-careers-text"> Know More</span>
                    </a>
                  </p>
                </li>
                {/* <li>
                  <p>
                    <strong className="medalPTagStrong">
                      Pediatric Dermatologist –{" "}
                    </strong>
                    Post MD Dermatology candidates with a minimum of 3 years of
                    experience and no intentions of private practice.
                    <a target="_blank" href="/pediatric-dermatologist">
                      <span className="car-btn-careers-text"> Know More</span>
                    </a>
                  </p>
                </li> */}
                <li>
                  <p>
                    <strong className="medalPTagStrong">Registrar - </strong>{" "}
                    Post MD Dermatology candidates with a minimum of 3 years
                    commitment and no intentions of private practice.
                    <a target="_blank" href="/registrar">
                      <span className="car-btn-careers-text"> Know More</span>
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <strong className="medalPTagStrong">
                      Technicians/Nurses -{" "}
                    </strong>{" "}
                    Technicians with diploma in O.T Technician/ degree in BSC OT
                    Technicians / GNM nursing / BSC nursing people with
                    experience in dermatology field is preferred.
                    <a href="/nurses" target="_blank">
                      <span className="car-btn-careers-text"> Know More</span>
                    </a>
                  </p>
                </li>
                {/* <li>
                  <p>
                    <strong className="medalPTagStrong">Nurses - </strong>
                    <a href="/nurses" target="_blank">
                      <span className="car-btn-careers-text"> Know More</span>
                    </a>
                  </p>
                </li> */}
                {/* <li>
                  <p>
                    <strong className="medalPTagStrong">
                      Nurse Educators -
                    </strong>
                    <a target="_blank" href="/nurse-educators">
                      <span className="car-btn-careers-text"> Know More</span>
                    </a>
                  </p>
                </li> */}
                <li>
                  <p>
                    <strong className="medalPTagStrong">
                      Visiting Consultant -
                    </strong>
                    <a target="_blank" href="/visiting-consultant">
                      <span className="car-btn-careers-text"> Know More</span>
                    </a>
                  </p>
                </li>
                {/* className=" cta-btn cta-btn-careers scrollto know-more-btn
                text-deco " */}
                {/* <li classname="presentationsLi">OT Technicians</li>
                <li classname="presentationsLi">
                  Nurses
                  <a
                    className=" cta-btn cta-btn-careers scrollto know-more-btn text-deco "
                    target="_blank"
                    href="/nurse-educators"
                  >
                    <span className="car-btn-careers-text"> Know More</span>
                  </a>
                </li>
                <li classname="presentationsLi ">
                  <a
                    // className=" cta-btn scrollto know-more-btn "
                    // target="_blank"
                    href="/nurse-educators"
                    classname="links-nurse "
                    style={{ fontSize: "16px" }}
                  >
                    Nurse Educators <i class="bx bx-link-external"></i>
                  </a>
                  <a
                    className=" cta-btn cta-btn-careers scrollto know-more-btn text-deco "
                    target="_blank"
                    href="/nurse-educators"
                  >
                    <span className="car-btn-careers-text"> Know More</span>
                  </a>
                  <br />
                sf  <a
                    className=" cta-btn cta-btn-careers scrollto know-more-btn text-deco "
                    target="_blank"
                    href="/nurse-educators"
                  >
                    know More
                  </a>sd
                </li>
                <li>
                  <p>
                    <strong className="medalPTagStrong">Consultants – </strong>
                    Post MD Dermatology candidates with a minimum of 3 years of
                    experience and no intentions of outside practice.
                  </p>
                </li> */}
              </ul>
              {/* <p>
                Technicians with diploma in O.T Technician/ degree in BSC OT
                Technicians / GNM nursing / BSC nursing people with experience
                in dermatology field is preferred.
              </p>
              <p>
                <strong className="medalPTagStrong">Registrar - </strong> Post
                MD Dermatology candidates with a minimum of 3 years commitment
                and no intentions of outside practice.
              </p>
              <p>
                <strong className="medalPTagStrong">Consultants – </strong>
                Post MD Dermatology candidates with a minimum of 3 years of
                experience and no intentions of outside practice.
              </p> */}
            </div>

            <div className="col-lg-6">
              <SectionTitle title={"Apply for Positions"} desc={""} />
              <form
                action="https://mail.digikit.in/cutis-contact-form/receive.php"
                method="post"
                role="form"
                className="php-email-form"
                enctype="multipart/form-data"
              >
                <div className="form-row">
                  <div className="col form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                      required
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="col form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                      required
                    />
                    <div className="validate"></div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 8 chars of subject"
                    required
                  />
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Presently working with (brief info)"
                  ></textarea>
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <span> Attach Your CV*</span>
                  <input type="file" id="subject1" name="attachment" />
                  <div className="validate"></div>
                </div>
                <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message" id="display-thank-you"></div>
                  <div className="sent-message"></div>
                  {submitText}
                </div>
                <div className="text-center">
                  <button type="submit" name="submit">
                    Submit
                  </button>
                </div>
              </form>
              {/* <form
                action="https://digikit.in/cutis-contact-form/receive.php"
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="form-row">
                  <div className="col form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Your Name"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 4 chars"
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="col form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Your Email"
                      data-rule="email"
                      data-msg="Please enter a valid email"
                    />
                    <div className="validate"></div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 8 chars of subject"
                  />
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Presently working with (brief info)"
                  ></textarea>
                  <div className="validate"></div>
                </div>
                <div className="form-group">
                  <span> Attach Your CV*</span>
                  <input type="file" id="subject" name="attachment" />
                  <div className="validate"></div>
                </div>
                <div className="mb-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                    Your message has been sent. Thank you!
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" name="submit">
                    Submit
                  </button>
                </div>
              </form> */}
            </div>
          </div>
        </div>
      </section>
      {/* End Contact Section */}
    </>
  );
};

export default CarrerForm;
